<template>
    <div>
        <div class="cont">
            <bread-crumb :pN="pN" :childName="pageName"></bread-crumb>
            <el-row>
                <el-col :span="17">
                    <div class="ltCont">
                        <h5 class="title" v-html="detail.title"></h5>
                        <div class="clear-fix pdate">
                            <div class="author-style">作者：<span>{{detail.author}}</span></div>
                            <div>在线时间：<span>{{detail.createDate}}</span></div>
                            <div class="w100" v-if="detail.keywordList && detail.keywordList.length>0">关键词：<span
                                    v-for="(item, index) in detail.keywordList" :key="index">{{item}}</span></div>
                        </div>
                        <div class="abs" v-if="detail.abstractCn"><span>摘要：</span>{{detail.abstractCn}}</div>
                        <div class="infoCont" oncopy="return false" oncut="return false;" v-html="detail.content">
                        </div>
                        <div class="clear-fix pdates" v-if="detail.reference">
                            <div class="reference">参考文献：</div>
                            <div v-html="detail.reference"></div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="7">
                    <div class="tab-rt">
                        <div class="relevant-anthology-wrap">
                            <div class="com-title">
                                <div>
                                    <img class="title-img" src="../assets/img/icon1.png" alt="" />
                                    <h5>文章来源</h5>
                                </div>
                            </div>
                            <div class="article-source">
                                <img :src="jkDetail.imgPath" v-if="jkDetail.imgPath" @click="toJkDetail" alt="" />
                                <img :src="jkDetail.titleImg" v-else @click="toWJDetail" alt="" />

                                <div class="article-source-main">
                                    <h5 @click="toJkDetail" v-if="jkDetail.name">{{jkDetail.name}}</h5>
                                    <h5 @click="toWJDetail" v-else>{{jkDetail.title}}</h5>

                                    <p v-if="jkDetail.author">主编：{{jkDetail.author}}</p>
                                    <p v-else>主编：{{jkDetail.author}}</p>
                                    <p v-if="jkDetail.description">集刊简介：<span v-html="jkDetail.description"></span></p>
                                    <p v-else>简介：<span v-html="jkDetail.abstractCn"></span></p>

                                </div>
                            </div>

                            <!-- <div class="relevant-anthology">
                                <img v-if="jkDetail && jkDetail.titleImg" :src="jkDetail.titleImg" alt=""
                                    @click="toJkDetail" style="cursor:pointer;" />
                                <img v-else alt="" />
                                <div class="relevant-anthology-main">
                                    <h5 @click="toJkDetail" v-if="jkDetail && jkDetail.title">
                                        {{jkDetail.title}}</h5>
                                    <p v-if="jkDetail && jkDetail.author">主编：{{jkDetail.author}}</p>
                                    <p v-if="jkDetail && jkDetail.abstractCn">集刊简介：{{jkDetail.abstractCn}}</p>
                                </div>
                            </div> -->
                            <!-- <ul>
                                <li v-for="(item, index) in jkDetail" :key="index" v-if="index>0 && item.title"
                                    @click="goRelevant('book', item)">{{item.title}}</li>
                            </ul> -->
                        </div>

                        <div class="relevant-article-wrap">
                            <div class="com-title">
                                <div>
                                    <img class="title-img" src="../assets/img/icon3.png" alt="" />
                                    <h5>相关推荐</h5>
                                </div>
                            </div>

                            <div class="relevant-article">
                                <ul>
                                    <li v-for="item in relevantList" :key="item.id"
                                        @click="goDetail(item.id, item.title)">{{item.searchTitle? item.searchTitle : item.title}}</li>
                                </ul>
                            </div>

                        </div>

                    </div>
                </el-col>
            </el-row>
        </div>
        <div id="mjs_tip" class="tip" style="position: absolute; left: 0; top: 0; display: none;"></div>
    </div>
</template>
<script>
import BreadCrumb from '../components/BreadCrumb'

export default {
    data() {
        return {
            detail: '',
            jkDetail: '',
            relevantList: [],
            pageName: '在线阅读',
            pN: ''
        }
    },
    components: {
        BreadCrumb
    },
    created() {
        if (this.$route.query.pN) {
            this.pN = decodeURI(this.$route.query.pN)
        } else {
            this.pN = ''
        }
        this.getDetail(this.$route.query.id)
        this.getArticelList(this.$route.query.id)
        this.jkDetail = JSON.parse(localStorage.getItem('jkDetail'))
    },
    watch: {
        $route(to, from) {
            this.getDetail(this.$route.query.id)
        }
    },
    beforeDestroy() {
        localStorage.removeItem('jkDetail')
    },
    mounted() {
        // setTimeout(function () {
        this.$nextTick(function() {
            var notes = document.getElementsByClassName('note1')
            for (var i = 0; i < notes.length; i++) {
                notes[i].innerHTML = '<font style="color:red">' + (i + 1) + '</font>'
            }
        })

        // },1000);
    },
    methods: {
        goBack() {
            this.$router.back()
        },
        toWJDetail () {
            this.$router.push({ path: '/collectionDetail/c', query: { id: this.jkDetail.id, pN: encodeURI(this.pageName) } })
        },
        goRelevant(type, item) {
            if (type == 'wenji') {
                this.$router.push({ path: '/collectionDetail/c', query: { id: item.id, pN: encodeURI(this.pageName) } })
            } else if (type == 'book') {
                this.$router.push({ path: '/c/c', query: { id: item.id, pN: encodeURI(this.pageName) } })
                // , title: item.title
            } else if (type == 'article') {
                this.$router.push({ path: '/aD/a', query: { id: item.id, pN: encodeURI(this.pageName) } })
                // title: item.title
            }
        },
        changeDate(data) {
            var arr = data.split('-')
            if (arr.length > 1) {
                return arr[0] + '-' + arr[1]
            } else {
                return arr[0]
            }
        },
        getDetail(id) {
            var _this = this
            _this.$http
                .get('/admin/api/article/readOnline', {
                    params: {
                        id: id
                    }
                })
                .then(res => {
                    _this.detail = res.data.data
                    if (res.data.data.keyWordCn) {
                        this.detail.keywordList = res.data.data.keyWordCn.split(',')
                    } else {
                        this.detail.keywordList = []
                    }
                    if (_this.detail.collectedPapersId) {
                        // _this.getJKDetail(_this.detail.collectedPapersId)
                    }
                    if (res.data.data && res.data.data.searchTitle) {
                        _this.pageName = res.data.data.searchTitle
                    } else {
                        _this.pageName = res.data.data.title
                    }
                    // setTimeout(function(){
                    _this.$nextTick(function() {
                        let notes = document.getElementsByClassName('note1')

                        for (var i = 0; i < notes.length; i++) {
                            notes[i].innerHTML = '<font style="color:red">' + (i + 1) + '</font>'
                        }
                        window.tip = {
                            Tips: function(ele) {
                                if (typeof ele == 'object') return ele
                                else if (typeof ele == 'string' || typeof ele == 'number') return document.getElementById(ele.toString())
                                return null
                            },
                            mousePos: function(e) {
                                var x, y
                                var e = e || window.event
                                return { x: e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft, y: e.clientY + document.body.scrollTop + document.documentElement.scrollTop }
                            },
                            start: function(obj) {
                                var self = this
                                var t = self.Tips('mjs_tip')
                                obj.onmousemove = function(e) {
                                    var mouse = self.mousePos(e)
                                    var toLeft = mouse.x + 10
                                    var toTop = mouse.y + 10
                                    t.style.left = toLeft + 'px'
                                    t.style.top = toTop + 'px'
                                    t.innerHTML = '注：'
                                    var str
                                    str = obj.getAttribute('tips')

                                    t.appendChild(document.createElement('div')).innerHTML = str
                                    t.style.display = ''
                                    var overTop = toTop + t.clientHeight - $(window).height() - $(window).scrollTop()
                                    var overLeft = toLeft + t.clientWidth - $(window).width() - $(window).scrollLeft()

                                    if (overTop > 0) {
                                        toTop = toTop - overTop - 2
                                        t.style.top = toTop + 'px'
                                    }
                                    if (overLeft > 0) {
                                        toLeft = toLeft - overLeft - 2
                                        t.style.left = toLeft + 'px'
                                    }
                                }
                                obj.onmouseout = function() {
                                    t.style.display = 'none'
                                }
                            }
                        }
                        // },500)
                    })
                })
        },
        // getJKDetail(id) {
        //     var _this = this
        //     _this.$http
        //         .get('/admin/api/cp/detail', {
        //             params: {
        //                 idOrName: id
        //             }
        //         })
        //         .then(res => {
        //             _this.jkDetail = res.data.data
        //             _this.jkDetail.firstDate = _this.changeDate(this.jkDetail.firstDate)
        //         })
        // },
        getArticelList(id) {
            var _this = this
            _this.$http
                .get('/admin/api/article/recommend', {
                    params: {
                        id: id
                    }
                })
                .then(res => {
                    if (res.data.code == 0) {
                        _this.relevantList = res.data.data
                    }
                })
        },
        toJkDetail() {
            //集刊
            this.$router.push({ path: '/c/c', query: { id: this.jkDetail.id, pN: encodeURI(this.pageName) } })
            // this.jkDetail.code
            // , title: title
        },
        goDetail(id) {
            // this.$router.push({ path: '/collectionDetail/c', query: { id: id, pN: encodeURI(this.pageName) } })
            this.$router.push({ path: '/aD/a', query: { id: id, pN: encodeURI(this.pageName) } })
            // this.$router.push({path: '/c/c', query: {id: id, title: title}})
        }
    }
}
</script>
<style lang="scss" scoped>
.back_box {
    width: 1200px;
    margin: 0 auto;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
}
.cont {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 50px;
}
.braed {
    margin: 15px 0;
}
.braed >>> .el-breadcrumb__inner.is-link:hover {
    color: #333;
    cursor: pointer;
}
.tab-rt {
    padding-left: 10px;
    margin-top: -10px;
    .relevant-anthology-wrap {
        padding: 0 18px;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        margin-bottom: 24px;
        .com-title {
            div {
                img {
                    height: 29px;
                }
            }
        }
        .article-source {
            display: flex;
            justify-content: space-between;
            padding-top: 14px;
            img {
                width: 106px;
                height: 142px;
                margin-bottom: 26px;
                box-shadow: 0px 0px 6px 1px rgba(15, 11, 5, 0.2);
            }
            .article-source-main {
                width: 162px;
                h5,
                p:nth-child(2) {
                    width: 162px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                h5 {
                    font-size: 16px;
                    margin-bottom: 10px;
                    cursor: pointer;
                }
                p {
                    font-size: 14px;
                    color: #666;
                    line-height: 22px;
                    span {
                        font-size: 14px;
                        color: #666;
                        line-height: 22px;
                    }
                }
                p:nth-child(3) {
                    width: 162px;
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    line-clamp: 4;
                    -webkit-box-orient: vertical;
                }
            }
        }
        // ul {
        //     margin-bottom: 13px;
        //     li {
        //         width: 278px;
        //         box-sizing: border-box;
        //         padding-left: 10px;
        //         overflow: hidden;
        //         text-overflow: ellipsis;
        //         white-space: nowrap;
        //         line-height: 36px;
        //         height: 36px;
        //         font-size: 16px;
        //         color: #333333;
        //         cursor: pointer;
        //         position: relative;
        //     }
        //     li::before {
        //         position: absolute;
        //         left: 0;
        //         top: 18px;
        //         content: '';
        //         width: 4px;
        //         height: 4px;
        //         border-radius: 50%;
        //         background: #ffac13;
        //     }
        // }
    }
    .relevant-article-wrap {
        padding: 0 18px;
        border: 1px solid #f5f5f5;
        box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
        margin-bottom: 24px;
        .com-title {
            div {
                img {
                    height: 29px;
                }
            }
        }
        .relevant-article {
            ul {
                padding: 10px 0 14px;
                li {
                    line-height: 37px;
                    height: 37px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    padding-left: 10px;
                    position: relative;
                    font-size: 16px;
                    color: #333;
                    width: 278px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    cursor: pointer;
                    box-sizing: border-box;
                }
                li::before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: #ffac13;
                    position: absolute;
                    left: 0;
                    top: 18px;
                }
            }
        }
    }
}
.contribut {
    padding-top: 4px;
    vertical-align: middle;
}
.contribut > div {
    width: 90px;
    height: 74px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    background: #bd1a2d;
    padding-top: 14px;
}
.contribut > img {
    width: 246px;
    height: 74px;
    vertical-align: middle;
}
.guss h5 {
    position: relative;
    height: 50px;
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: bold;
    border-bottom: 4px solid #bd1a2d;
    color: #bd1a2d;
}
.guss h5 span {
    position: absolute;
    top: -6px;
    height: 50px;
    line-height: 50px;
    display: inline-block;
    width: 176px;
    border-bottom: 4px solid #bd1a2d;
    border-bottom-right-radius: 3px;
}
.guss ul {
    padding: 10px;
    background: #f7f7f7;
}
.guss ul li {
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.guss ul li:before {
    content: '';
    float: left;
    margin-top: 8px;
    margin-right: 14px;
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #b5b5b5;
    border-radius: 50%;
}
.guss ul li a {
    color: #292929;
}
.iedolImg {
    margin-bottom: 30px;
    width: 340px;
}
.iedolImg > img {
    width: 124px;
    height: 170px;
    display: inline-block;
    background: #ebf0f4;
    cursor: pointer;
}
.iedolMsg {
    width: 216px;
    padding: 4px 0 6px 20px;
}
.iedolMsg .itle {
    font-size: 16px;
    line-height: 21px;
    font-weight: bold;
    margin-bottom: 6px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    cursor: pointer;
}
.iedolMsg .author {
    margin-bottom: 8px;
}
.iedolMsg .author span {
    margin-right: 4px;
}
.author-style {
    margin-top: 20px !important;
}
.iedolMsg .abs {
    line-height: 22px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
.iedolNum a {
    display: inline-block;
    width: 100px;
    line-height: 32px;
    text-align: center;
    border-radius: 3px;
    border: 1px solid #e5e5e5;
    margin: 0 10px 10px 0;
}
.iedolNum a:hover,
.iedolNum a.active {
    color: #fff;
    border-color: #bd1a2d;
    background: #bd1a2d;
}

.ltCont {
    margin-top: 10px;
}
.title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 6px;
}
.title >>> .role4{
    vertical-align: middle;
    width: 16px !important;
    height: 16px !important;
    margin-top: -2px;
}
.pdate > div {
    width: 100%;
    margin: 5px 12px 15px 0;
    color: #292929;
    font-weight: bold;
}
.pdate > div.fl > span {
    font-weight: 500;
    width: 300px;
    display: inline-block;
}
.pdate span {
    margin-right: 8px;
}
.pdates .reference {
    width: 100%;
    margin: 5px 12px 15px 0;
    color: #292929;
    font-weight: bold;
}
.abs {
    color: #292929;
    line-height: 22px;
    margin-bottom: 20px;
}
.abs > span {
    font-weight: bold;
    color: #bd1a2d;
}
.infoCont {
    padding: 20px 0;
    border-top: 1px dashed #e6e6e6;
}
.infoCont >>> p {
    line-height: 28px;
    text-indent: 2em;
}
.infoCont >>> img {
    max-width: 800px;
    margin: 0 auto;
    height: auto;
}
.infoCont >>> p .role3 {
    /* 4补字 3行内 */
    display: inline-block;
    max-height: 18px;
}
.infoCont >>> p .role4 {
    display: inline-block;
    width: 13px !important;
    height: 13px !important;
}
#mjs_tip {
    font-size: 12px;
}
.tip {
    width: 345px;
    border: 1px solid #d1e4f2;
    padding: 6px 10px 10px;
    background: #fff url(https://www.lieguozhi.com/skwx_lgz/images/z_tiptitle.jpg) repeat-x;
    font-weight: bold;
    font-size: 13px;
    color: #0f598e;
}
#mjs_tip >>> div {
    font-weight: normal;
    margin-top: 15px;
    color: #000;
    word-break: break-all;
}
#mjs_tip >>> div .role3 {
    display: inline-block;
    height: 14px !important;
    margin-bottom: -2px;
}
</style>
